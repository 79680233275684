import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';

import { RedButton, TransparentButtonInverted, Toggle, InputTextField } from '../../../components';
import iconSave from '../../../assets/images/icon-save.svg';
import Router from '../../../routes/router';

import {DropdownSingleSelection} from '../../../components';
import {DropdownValueType} from '../../../components/base/Dropdowns/types';
import {EXTERNAL_DROPDOWN_VALUES, UPDATE_USER, GET_EXTERNAL_USER, CREATE_USER, LIST_COMPANIES, DELETE_USER} from '../userQueries';

import { emailValidationRegEx, pwcEmailRegEx } from '../../../utils/helpers';
import * as messages from '../../../utils/Constant/messages';
import CircularProgress from '@mui/material/CircularProgress';
import DialogBox from '../../../components/base/DialogBox';
import { ErrorModal } from '../../../utils/ErrorModal';
import { UserStore } from '../../../context/user-store';

const AddEditExternalUser: React.FC<any> = () => {
    const { state: { user } } = useContext(UserStore);
    const params = useParams<{
        userId: string;
        addEdit: string;
    }>();
    const userId = decodeURIComponent(params.userId ?? '');
    const [titleLabel, setTitleLabel] = useState<string>('Add User');
    const [isEdit, setIsEdit] = useState<boolean | undefined>(undefined);
    const [savingUser, setSavingUser] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>('');
    const [userSurname, setUserSurname] = useState<string>('');
    const [userEnabled, setUserEnabled] = useState<boolean>(true);
    const [selectedJobTitle, setSelectedJobTitle] = useState<string>('');
    const [selectedEmail, setSelectedEmail] = useState<string>('');
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>('');

    const [emailError, setEmailError] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string>('');
    const [userSurnameError, setUserSurnameError] = useState<string>('');
    const [countryError, setCountryError] = useState<string>('');
    const [companyError, setCompanyError] = useState<string>('');

    const [allCompaniesForDrodpdown, setAllCompaniesForDrodpdown] = useState<DropdownValueType[]>([]);
    const [allCountriesForDrodpdown, setAllCountriesForDrodpdown] = useState<DropdownValueType[]>([]);

    const [isDialogBoxApproveOpen, setIsDialogBoxApproveOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const [openErrorModal,setOpenErrorModal] = useState(false)

    const { data:dataDropdownValues, loading: loadingDropdownValues, error:errorDropdownValues } = useQuery(EXTERNAL_DROPDOWN_VALUES,{
        fetchPolicy: 'network-only',
        variables: {
            pagination: {
                order: {
                    field: 'name',
                    isAsc: true
                },
            },
            filters: {
                countryEnabled: true
            }
        }
    });
    const [getCompanies] = useLazyQuery(LIST_COMPANIES,{
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAllCompaniesForDrodpdown(res.clientSummaries.items);
        },
        onError: (err) => {
            console.log(err);
        }
    });


    useEffect(() => {
        if (params && params.addEdit) {
            setTitleLabel(decodeURIComponent(params.addEdit) + ' external user');
            if(decodeURIComponent(params.addEdit) === 'Add'){
                setIsEdit(false);
            } else if(decodeURIComponent(params.addEdit) === 'Edit'){
                setIsEdit(true);
            }
        }
    }, [params]);

    const [getExternalUserDetails] = useLazyQuery(GET_EXTERNAL_USER, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setUserName(res.admin_user.firstName);
            setUserSurname(res.admin_user.lastName);
            setUserEnabled(res.admin_user.userEnabled);
            setSelectedJobTitle(res.admin_user.jobTitle);
            setSelectedEmail(res.admin_user.email);
            !!res.admin_user.clients && res.admin_user.clients.length > 0 && setSelectedCompany(res.admin_user.clients[0].name);
            setSelectedPhoneNumber(res.admin_user.phone);
            !!res.admin_user.clients && res.admin_user.clients.length > 0 && setSelectedCountry(res.admin_user.clients[0].country);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (typeof isEdit === 'boolean' && isEdit) {
            getExternalUserDetails({
                variables: {
                    query: {
                        id: parseInt(decodeURIComponent(params.userId ?? ''))
                    }
                }
            })
        }
    }, [isEdit]);

    useEffect(() => {
        if(!!selectedCountry && typeof isEdit === 'boolean' && !isEdit){
            const selCountryId = allCountriesForDrodpdown.filter(country => country.name === selectedCountry)
            getCompanies({
                variables: {
                    filters:{
                        countryIds: selCountryId && [selCountryId[0].id],
                        clientEnabled: true
                    },
                    pagination: {
                        order: {
                            field: 'name',
                            isAsc: true
                        },
                    }
                }
            })
            setSelectedCompany('');
        }
        if(typeof isEdit === 'boolean' && isEdit) {
            const selCountryId = allCountriesForDrodpdown.filter(country => country.name === selectedCountry)
            getCompanies({
                variables: {
                    filters:{
                        countryIds: selCountryId && [selCountryId[0].id],
                        clientEnabled: true
                    },
                    pagination: {
                        order: {
                            field: 'name',
                            isAsc: true
                        },
                    }
                }
            })
        }
    }, [selectedCountry]);

    const [createExternalUser] = useMutation(CREATE_USER);
    const [updateExternalUser] = useMutation(UPDATE_USER);
    const [deleteExternalUser] = useMutation(DELETE_USER);

    useEffect(() => {
        if (loadingDropdownValues || errorDropdownValues) {
            return;
        }

        setAllCountriesForDrodpdown(dataDropdownValues.countrySummaries.items);

    }, [dataDropdownValues, loadingDropdownValues, errorDropdownValues])

    const statusToggleLabels = {
        trueLabel: 'Enabled',
        falseLabel: 'Disabled'
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
        setUserNameError('');
    };
    const onSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserSurname(event.target.value);
        setUserSurnameError('');
    };
    const onJobTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedJobTitle(event.target.value)
    };
    const onSelectedEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedEmail(event.target.value);
        setEmailError('');
    };
    const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPhoneNumber(event.target.value);
    };

    const onSubmit = () => {
        setSavingUser(true);
        const chosenCompany = allCompaniesForDrodpdown.find((company:DropdownValueType) => company.name === selectedCompany);
        const chosenCountry = allCountriesForDrodpdown.find((country:DropdownValueType) => country.name === selectedCountry);
        let isError = false
        if (!selectedEmail) {
            setEmailError('Required');
            isError=true;
        } else if ( !emailValidationRegEx.test(selectedEmail) ) {
            setEmailError(messages.ERROR_USERS_EMAIL_INVALID);
            isError=true;
        } else if( pwcEmailRegEx.test(selectedEmail) ){
            setEmailError(messages.ERROR_USERS_EMAIL_WRONG_DOMAIN);
            isError=true;
        }
        if(!userName){
            setUserNameError('Required');
            isError=true;
        }
        if(!userSurname){
            setUserSurnameError('Required');
            isError=true;
        }
        if(!selectedCompany){
            setCompanyError('Required');
            isError=true;
        }
        if(!selectedCountry){
            setCountryError('Required');
            isError=true;
        }

        if(isError){
            setSavingUser(false);
            return
        }

        if(typeof isEdit === 'boolean' && !isEdit){
            createExternalUser({
                variables:{
                    data:[{
                        firstName: userName,
                        lastName: userSurname,
                        userEnabled: userEnabled,
                        jobTitle: selectedJobTitle,
                        phone: selectedPhoneNumber,
                        email: selectedEmail,
                        role: 'EXTERNAL',
                        clientUsers: [{
                            clientId: chosenCompany && chosenCompany.id,
                            clientUserEnabled: true
                        }],
                        countryUsers: [{
                            countryId: chosenCountry && chosenCountry.id,
                            countryUserEnabled: true,
                            campaignCreateEnabled: false
                        }]
                    }]
                }
            }).then(() => {
                setSavingUser(false);
                Router.goToUserManagement('true');
            })
            .catch(err => {
                setSavingUser(false);
                if (
                    err
                        .toString()
                        .search(
                            'Error: GraphQL error: User with email:'
                        ) !== -1 &&
                    err.toString().search('already exist') !== -1
                ) {
                    setEmailError(messages.ERROR_USERS_EMAIL_DUPLICATE);
                }
            })
        } else if(typeof isEdit === 'boolean' && isEdit){
            updateExternalUser({
                variables:{
                    data:{
                        id: !!parseInt(userId) ? parseInt(userId) : undefined,
                        firstName: userName,
                        lastName: userSurname,
                        userEnabled,
                        jobTitle: selectedJobTitle,
                        phone: selectedPhoneNumber,
                        email: selectedEmail,
                        role: 'EXTERNAL'
                    }
                }
            }).then(() => {
                setSavingUser(false);
                Router.goToUserManagement();
            })
            .catch(err => {
                setSavingUser(false);
                console.log("Error updating external users - ", err)
            })
        }
    }

    const deleteHandler = () => {
        setIsDialogBoxApproveOpen(true);
    };

    const handleDelete = () => {
        deleteExternalUser({
            variables: {
                data: {
                    userId: !!parseInt(userId) ? parseInt(userId) : undefined
                }
            }
        }).then((a: any) => {
            Router.goToUserManagement('deleted');
        }
        ).catch((error: any) => {
            console.log("Error deleting External users - ", error.message);
            setIsDialogBoxApproveOpen(false);
            setOpenErrorModal(true);
            setMessage(error.message);
            
            
        })
    };

    return (
        <>
        {savingUser ? (
            <div style={{
                display : 'flex',
                alignItems: 'center',
                justifyContent : 'center',
                marginTop : '45%'}}>
            <CircularProgress />
            </div>
        ) :
(
        <MainWrapper>
            <HeaderWrapper>
                <Header>
                    <h1>{titleLabel}</h1>
                    <ButtonsWrapper>
                        <CancelButton disabled={false} onClick={() => Router.goToUserManagement()}>
                            Cancel
                        </CancelButton>
                        {isEdit &&  
                     <DeleteButton disabled={false} onClick={deleteHandler}>
                            Delete
                        </DeleteButton>
                    }
                        <SaveButton
                            disabled={false}
                            onClick={onSubmit}
                        >
                            <img src={iconSave} alt="add user icon" />
                            Save
                        </SaveButton>
                    </ButtonsWrapper>
                </Header>
            </HeaderWrapper>

            { typeof isEdit === 'boolean' && isEdit &&
                  <DialogBox
                  buttonSaveText="Done"
                  buttonCancelText="Cancel"
                  content={
                    <div>
                    <p><strong>Warning:</strong> Deleting user "{userName}" will permanently remove it.</p>
                    <p>Are you sure you want to continue?</p>
                  </div>    
                    }
                  open={isDialogBoxApproveOpen}
                  handleClose={() => setIsDialogBoxApproveOpen(false)}
                  handleSubmit={() => handleDelete()}
              />
                  }
            <div>
                <TitleAndToggles>
                    <TogglesWrapper>
                        Status
                        <StatusToogle
                            labels={statusToggleLabels}
                            state={userEnabled}
                            actionFunc={() => setUserEnabled(!userEnabled)}
                            cellID="Status-toogle"
                        />
                    </TogglesWrapper>
                </TitleAndToggles>
                <UserValues>
                    <DetailText
                        labelText="First name"
                        value={userName}
                        isError={userNameError.length > 0}
                        errorMessage={userNameError}
                        onBlurFunc={onNameChange}
                        onChangeFunc={onNameChange}
                        placeholderText="Add first name"
                    />
                    <DetailText
                        labelText="Last name"
                        value={userSurname}
                        isError={userSurnameError.length > 0}
                        errorMessage={userSurnameError}
                        onBlurFunc={onSurnameChange}
                        onChangeFunc={onSurnameChange}
                        placeholderText="Add last name"
                    />
                    <DetailText
                        labelText="Job title(optional)"
                        value={selectedJobTitle}
                        isError={false}
                        onChangeFunc={onJobTitleChange}
                        placeholderText="Add job title"
                    />
                    <DetailText
                        labelText="Email"
                        value={selectedEmail}
                        isError={emailError.length > 0}
                        errorMessage={emailError}
                        onChangeFunc={onSelectedEmailChange}
                        placeholderText="Add email address"
                       // disabled={typeof isEdit === 'boolean' && isEdit}
                    />
                    <Detail
                        labelText="Country"
                        isDisabled={typeof isEdit === 'boolean' && isEdit}
                        selectedValue={selectedCountry}
                        setValue={setSelectedCountry}
                        errorMessage={countryError}
                        placeholder="Add country"
                        allValues={allCountriesForDrodpdown}
                        width="14em"
                    />
                    <Detail
                        labelText="Company"
                        isDisabled={typeof isEdit === 'boolean' && isEdit || !selectedCountry}
                        selectedValue={selectedCompany}
                        setValue={setSelectedCompany}
                        errorMessage={companyError}
                        placeholder="Add company"
                        allValues={allCompaniesForDrodpdown}
                        width="14em"
                    />
                    <DetailText
                        labelText="Phone number (optional)"
                        value={selectedPhoneNumber}
                        isError={false}
                        onChangeFunc={onPhoneNumberChange}
                        placeholderText="Add phone number"
                    />
                </UserValues>
            </div>
            <ErrorModal
                openErrorModal={openErrorModal}
                message={message}
                setOpenErrorModal={setOpenErrorModal}
            />
            </MainWrapper>
            )
        }
        </>
    );
};
const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled('div')`
    height: 165px;
`;

const Header = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #151515;
    height: 107px;
    color: white;
    position: absolute;
    width: 78%;
    left: 0;
    padding-left: 22%;
`;

const CancelButton = styled(TransparentButtonInverted)`
    width: 180px;
    font-size: 18px !important;
`;

const DeleteButton = styled(TransparentButtonInverted)`
    width: 180px;
    margin-left: 10px !important;
    font-size: 18px !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    padding-right: 56px;
    height: 50%;
`;

const TitleAndToggles = styled('div')`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

const TogglesWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const StatusToogle = styled(Toggle)`
    margin-left: 0.75rem;
`;

const UserValues = styled('div')`
    margin-top: 25px;
`;

const Detail = styled(DropdownSingleSelection)<{
    error?: boolean;
}>`
    display: inline-block;
    margin-right: 8%;
    margin-bottom: 11px;
    overflow: hidden;
    .dropdown-child {
        margin-bottom: '0px';
    }
`;

const DetailText = styled(InputTextField)`
    display: inline-block;
    margin-right: 8%;
    margin-bottom: 11px;
    overflow: hidden;
`;

export default AddEditExternalUser;
