import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';

import Router from '../../../routes/router';

import {
    RedButton,
    SearchField,
    Tabs,
    DropdownSingleSelection,
    SuccessModal,
    ExportButton,
    Toggle
} from '../../../components';
import NewTable from '../../../components/Table/Table';

import iconSave from '../../../assets/images/icon-save.svg';
import iconDownload from '../../../assets/images/download-btn.svg';
import arrowIcon from '../../../assets/images/icon-indicator.svg';

import { TableRow as TableRowInter } from '../../../components/Table/types';
import {
    CountryListModel,
    CountryListPage,
    ClientListModel,
    ClientListPage,
    FrameworkDetailFilters,
    CountrySummaries,
    ClientSummaries,
    FrameworkOrder,
    CountryUserOverrides,
    FrameworkSetting
} from '../frameworkTypes';
import {
    getClientsTableHeader,
    getCountriesTableHeader,
    setTableRowsWithClient,
    setTableRowsWithCounrty,
    getFilters,
    saveHandler,
    exportCompanySummariesByFrameworkCSV,
    exportCountrySummariesByFrameworkCSV
} from './frameworkDetailHelpers';
import { disabledEnabledDropdownValues, createTempCountryUserEnabled, tabLabels, TabOptions } from '../frameworkHelpers';
import { FrameworkDetailsProps,DropdownCountry } from '../frameworkTypes';
import {
    CLIENT_SUMMARIES_BYFRAMEWORK,
    COUNTRY_SUMMARIES_BYFRAMEWORK,
    EDIT_FRAMEWORK,
    GET_SETTINGS_BYFRAMEWORK,
    UPDATE_FRAMEWORK_SETTINGS_STATUS
} from '../frameworkQueries';
import { LIST_COUNTRIES } from '../../ManageCompanies/companyQueries';

import * as messages from '../../../utils/Constant/messages';
import {sortAlphabetically} from '../../../utils/helpers';
import { CircularProgress } from '@mui/material';
import { statusToggleLabels } from '../../ManageSettings/settingsHelpers';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransparentButtonInverted } from '../../../components';
import { DELETE_FRAMEWORK } from '../../NotificationHub/notificationQueries';
import DialogBox from '../../../components/base/DialogBox';
import { ErrorModal } from '../../../utils/ErrorModal';

const FrameworkDetails: React.FC = () => {
    const params = useParams<FrameworkDetailsProps>();
    const perPage = 50;
    const frameworkName = decodeURIComponent(params.frameworkName ?? '');
    const frameworkId = decodeURIComponent(params.frameworkId ?? '');
    const [frameworkAccess, setFrameworkAccess] = useState<string>('All');
    const [clientPage, setClientPage] = useState<number>(0);
    const [countryPage, setCountryPage] = useState<number>(0);
    const [countryTableRows, setCountryTableRows] = useState<TableRowInter[]>();
    const [clientTableRows, setClientTableRows] = useState<TableRowInter[]>();
    const [countrySummaries, setCountrySummaries] = useState<CountryListPage>();
    const [clientSummaries, setClientSummaries] = useState<ClientListPage>();
    const [frameworkSettings, setFrameworkSettings] = useState<FrameworkSetting[]>([]);
    const [tempFrameworkSettings, setTempFrameworkSettings] = useState<any>({});
    const [searchCountryText, setSearchCountryText] = useState<string>('');
    const [searchClientText, setSearchClientText] = useState<string>('');
    const [currentTab, setCurrentTab] = useState<string>(TabOptions.COUNTRIES);
    const [deleteFramework] = useMutation(DELETE_FRAMEWORK);
    const [isDialogBoxApproveOpen, setIsDialogBoxApproveOpen] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [frameworkError, setFrameworkError] = useState('');
    const [allCountries, setAllCountries] = useState<DropdownCountry[]>([
        {
            id: 0,
            name: 'All',
            value:'All'
        }
    ]);
    const [allSelectedFrmByCountries, setAllSelectedFrmByCountries] = useState<
        number[]
    >([]);
    const [order, setOrder] = useState<FrameworkOrder|undefined>({field:'name',isAsc:true});
    const [allSelectedFrmByClients, setAllSelectedFrmByClients] = useState<
        number[]
    >([]);
    const [
        allInteractedFrmByCountries,
        setAllInteractedFrmByCountries
    ] = useState<number[]>([]);
    const [allInteractedFrmByClients, setAllInteractedFrmByClients] = useState<
        number[]
    >([]);
    const [selectedCountries, setSelectedCountries] = useState<string>('All');
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [filters, setFilters] = useState<FrameworkDetailFilters>({
        countryIds: undefined,
        frameworkEnabled: undefined
    });
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [countryUserOverrides,setCountryUserOverrides] = useState<CountryUserOverrides[]>([]);
    const { loading, data } = useQuery(LIST_COUNTRIES, {
        fetchPolicy: 'cache-first',
        variables: {
            pagination: {}
        }
    });
    const [editFramework] = useMutation(EDIT_FRAMEWORK);
    const handleFrmByCountriesToggle = (cellID: number) => {
        if (allSelectedFrmByCountries.includes(cellID)) {
            const arrWithoutCell = allSelectedFrmByCountries.filter(
                (selFr: number) => {
                    return selFr !== cellID;
                }
            );
            setAllSelectedFrmByCountries(arrWithoutCell);
        } else {
            setAllSelectedFrmByCountries([
                ...allSelectedFrmByCountries,
                cellID
            ]);
        }

        if (!allInteractedFrmByCountries.includes(cellID)) {
            setAllInteractedFrmByCountries([
                ...allInteractedFrmByCountries,
                cellID
            ]);
        }
    };

    const handleFrmByClientsToggle = (cellID: number) => {
        if (allSelectedFrmByClients.includes(cellID)) {
            const arrWithoutCell = allSelectedFrmByClients.filter(
                (selFr: number) => {
                    return selFr !== cellID;
                }
            );
            setAllSelectedFrmByClients(arrWithoutCell);
        } else {
            setAllSelectedFrmByClients([...allSelectedFrmByClients, cellID]);
        }
        if (!allInteractedFrmByClients.includes(cellID)) {
            setAllInteractedFrmByClients([
                ...allInteractedFrmByClients,
                cellID
            ]);
        }
    };

    const changeFrameworkSettingsStatus = (name: string, status: boolean, id: number) => {
        setOpen(true);
        setTempFrameworkSettings({
            name,
            status,
            id
        });
    }

    const [getCountriesSummaries,{loading:countryLoading}] = useLazyQuery<CountryListModel>(
        COUNTRY_SUMMARIES_BYFRAMEWORK,
        {
            fetchPolicy: 'network-only',
            onCompleted: res => {
                setCountrySummaries(res.countrySummariesByFramework);
                const allEnabledFrameworks = res.countrySummariesByFramework.items
                    .filter(
                        (country: CountrySummaries) =>
                            country.frameworkEnabled &&
                            !allInteractedFrmByCountries.includes(country.id)
                    )
                    .map((country: CountrySummaries) => country.id);
                const allUniqueSelectedIds = Array.from(
                    new Set([
                        ...allEnabledFrameworks,
                        ...allSelectedFrmByCountries
                    ])
                );
                setAllSelectedFrmByCountries(allUniqueSelectedIds);
            },
            onError: err => {
                console.log(err);
            }
        }
    );

    const [getClientSummaries,{loading:clientLoading}] = useLazyQuery<ClientListModel>(
        CLIENT_SUMMARIES_BYFRAMEWORK,
        {
            fetchPolicy: 'network-only',
            onCompleted: res => {
                setClientSummaries(res.clientSummariesByFramework);
                const allEnabledFrameworks = res.clientSummariesByFramework.items
                    .filter(
                        (client: ClientSummaries) =>
                            client.frameworkEnabled &&
                            !allInteractedFrmByClients.includes(client.id)
                    )
                    .map((client: ClientSummaries) => client.id);
                const allUniqueSelectedIds = Array.from(
                    new Set([
                        ...allEnabledFrameworks,
                        ...allSelectedFrmByClients
                    ])
                );
                setAllSelectedFrmByClients(allUniqueSelectedIds);
            },
            onError: err => {
                console.log(err);
            }
        }
    );

    const [getAllSettings, {loading: settingsLoading}] = useLazyQuery<FrameworkSetting[]>(
        GET_SETTINGS_BYFRAMEWORK,
        {
            fetchPolicy: 'network-only',
            onCompleted: (res: any) => {
                setFrameworkSettings(res.frameworkSettingsByFrameworkId);
            },
            onError: err => {
                setFrameworkSettings([]);
                console.log(err);
            }
        }
    );

    const [updateFrameworkSettings] = useMutation(UPDATE_FRAMEWORK_SETTINGS_STATUS, {
        onCompleted: (res: any) => {
            getFrameworkSettings();
            setTempFrameworkSettings({});
            setOpen(false);
        },
        onError: err => {
            setTempFrameworkSettings({});
            setOpen(false);
            console.log(err);
        }
    });

    const getFrameworkSettings = () => {
        getAllSettings({
            variables: {
                frameworkId: parseInt(frameworkId)
            }
        });
    };

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleDisagree = () => {
        setOpen(false);
    };

    const handleAgree = () => {
        updateFrameworkSettings({
            variables: {
                id: tempFrameworkSettings.id,
                status: !tempFrameworkSettings.status
            }
        });
    };

    useEffect(() => {
        if (data && data.countrySummaries && data.countrySummaries.items) {

            const countriesWithValue:DropdownCountry[]=data.countrySummaries.items.map((item:any)=>{
                return{
                    id:item.id,
                    name:item.name,
                    value:item.isoCode
                }
            })
            sortAlphabetically(countriesWithValue, 'name');
            const countriesResponse = [
                ...allCountries,
                ...countriesWithValue
            ];
            setAllCountries(countriesResponse);
        }
    }, [data, loading]);

    useEffect(() => {
        const rows = createTempCountryUserEnabled(allSelectedFrmByCountries,allInteractedFrmByCountries);
        setCountryUserOverrides(rows);
    }, [allSelectedFrmByCountries,allInteractedFrmByCountries]);

    useEffect(() => {
        getFilters(
            selectedCountries,
            allCountries,
            frameworkAccess,
            setFilters
        );
    }, [frameworkAccess, selectedCountries]);

    useEffect(() => {
        setOrder({field:'name',isAsc:true});
        setFrameworkAccess('All');
        setSelectedCountries('All');
     }, [currentTab]);

    useEffect(() => {
        if (currentTab === TabOptions.COUNTRIES) {
            getCountriesSummaries({
                variables: {
                    frameworkId: parseInt(frameworkId),
                    pagination: {
                        order,
                        skip: countryPage * perPage,
                        take: perPage
                    },
                    search: searchCountryText,
                    filters
                }
            });
        }
        return undefined;
    }, [countrySummaries, currentTab, countryPage, filters, searchCountryText,order]);

    useEffect(() => {
        if (currentTab === TabOptions.COMPANIES) {
            getClientSummaries({
                variables: {
                    countries:countryUserOverrides,
                    frameworkId: parseInt(frameworkId),
                    pagination: {
                        order,
                        skip: clientPage * perPage,
                        take: perPage
                    },
                    search: searchClientText,
                    filters
                }
            });
        }
        return undefined;
    }, [currentTab, clientPage, filters, searchClientText,order]);

    useEffect(() => {
        if (currentTab === TabOptions.SETTINGS) {
            getFrameworkSettings();
        }
        return undefined;
    }, [currentTab]);

    useEffect(() => {
        if (!countrySummaries || currentTab !== TabOptions.COUNTRIES) {
            return undefined;
        }

        const rows = setTableRowsWithCounrty(
            allSelectedFrmByCountries,
            handleFrmByCountriesToggle,
            countrySummaries
        );

        setCountryTableRows(rows);
    }, [countrySummaries, allSelectedFrmByCountries, openSuccessModal]);

    useEffect(() => {
        if (!clientSummaries || currentTab !== TabOptions.COMPANIES) {
            return undefined;
        }

        const rows = setTableRowsWithClient(
            allSelectedFrmByClients,
            handleFrmByClientsToggle,
            clientSummaries
        );

        setClientTableRows(rows);
    }, [clientSummaries, allSelectedFrmByClients, openSuccessModal]);

    const handleDelete = () =>{
        setIsDialogBoxApproveOpen(true);
    }

    const handleDeleteFramework = () => {
            if (!frameworkId) {
                throw new Error('frameworkId cannot be null or empty');
              }
            deleteFramework({
                variables: {
                    data: {frameworkId: parseInt(frameworkId)}

                }
            }).then(() => {
                Router.goToManageFrameworks('true');

            }
        ).catch((error: any) => {
            setIsDialogBoxApproveOpen(false);
            setFrameworkError(error.message);
            setOpenErrorModal(true);
        })
    };
    const saveChanges = () => {
        saveHandler(
            allInteractedFrmByCountries,
            allInteractedFrmByClients,
            editFramework,
            frameworkId,
            allSelectedFrmByCountries,
            allSelectedFrmByClients,
            setOpenSuccessModal,
            setAllInteractedFrmByCountries,
            setAllInteractedFrmByClients
        );
    };

    const createSortHandler = (property: string) => {
        if(order&&order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }
    };

    const handleChangeClientPage = (event: unknown, newPage: number) => {
        setClientPage(newPage);
    };

    const handleChangeCountryPage = (event: unknown, newPage: number) => {
        setCountryPage(newPage);
    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    const countrySearchCallback=(value:string) => {
        setSearchCountryText(value);
        setCountryPage(0);
    }

    const clientSearchCallback=(value:string) => {
        setSearchClientText(value);
        setClientPage(0);
    }

    const countriesTableHeader = getCountriesTableHeader(createSortHandler);
    const clientsTableHeader = getClientsTableHeader(createSortHandler);

    const countryTablePagination = {
        rowsPerPage: perPage,
        currentPage: countryPage,
        handleChangePage: handleChangeCountryPage,
        totalCount: countrySummaries && countrySummaries.total
    };

    const clientTablePagination = {
        rowsPerPage: perPage,
        currentPage: clientPage,
        handleChangePage: handleChangeClientPage,
        totalCount: clientSummaries && clientSummaries.total
    };

    const isDisabled =
        allInteractedFrmByCountries.length === 0 &&
        allInteractedFrmByClients.length === 0;

    return (
        <>
            <MainWrapper>
                <div>
                    <BackLink
                        onClick={() => Router.goToManageFrameworks()}
                    >
                        <img src={arrowIcon} alt="back icon" />
                        Back to frameworks
                    </BackLink>
                    <TitleAndButtons>
                        <CountryText>
                            <h1>{frameworkName}</h1>
                        </CountryText>
                        <ButtonsWrapper>
                        <DeleteButton onClick={handleDelete}>
                            Delete
                        </DeleteButton>
                        <SaveButton disabled={isDisabled} onClick={saveChanges}>
                                <img src={iconSave} alt="add country icon" />
                                Save changes
                            </SaveButton>
                        </ButtonsWrapper>
                    </TitleAndButtons>
                    <DialogBox
                  buttonSaveText="Done"
                  buttonCancelText="Cancel"
                  content={
                      <div>
                        <p><strong>Warning:</strong> Deleting this framework will permanently remove it.</p>
                        <p>Are you sure you want to continue?</p>
                      </div>
                    }
                  open={isDialogBoxApproveOpen}
                  handleClose={() => setIsDialogBoxApproveOpen(false)}
                  handleSubmit={() => handleDeleteFramework()}
              />
                    <Tabs
                        tabLabels={tabLabels}
                        activeTab={currentTab}
                        action={handleChangeTabs}
                    />
                    
                    {currentTab === TabOptions.COUNTRIES && (
                        <>
                            <TableFiltersAndSearch>
                                <TableFilters>
                                    <DropdownSingleSelection
                                        labelText="Country"
                                        isDisabled={false}
                                        selectedValue={selectedCountries}
                                        setValue={setSelectedCountries}
                                        allValues={allCountries}
                                        width="10em"
                                    />
                                    <DropdownSingleSelection
                                        labelText="Framework Access"
                                        isDisabled={false}
                                        selectedValue={frameworkAccess}
                                        setValue={setFrameworkAccess}
                                        allValues={disabledEnabledDropdownValues}
                                        width="10em"
                                    />
                                </TableFilters>
                                <SearchAndSave>
                                {exportSpinner ? (
                                        <CircularProgress />
                                    ) : (
                                        <ExportButton onClick={()=>exportCountrySummariesByFrameworkCSV(setExportSpinner,{frameworkId,countryIds:filters.countryIds,frameworkEnabled:filters.frameworkEnabled})}>
                                            <img src={iconDownload} alt="download" />
                                        </ExportButton>
                                    )}
                                    <SearchField
                                        placeholder="Search"
                                        callback={(value: string) =>
                                            countrySearchCallback(value)
                                        }
                                    />
                                </SearchAndSave>
                            </TableFiltersAndSearch>
                            {countryLoading?(
                                        <CircularProgress />
                                    ):
                            <CustomTable
                                header={countriesTableHeader}
                                tablePagination={countryTablePagination}
                                rows={countryTableRows}
                            />}
                        </>
                    )}
                    {currentTab === TabOptions.COMPANIES && (
                        <>
                            <TableFiltersAndSearch>
                                <TableFilters>
                                    <DropdownSingleSelection
                                        labelText="Country"
                                        isDisabled={false}
                                        selectedValue={selectedCountries}
                                        setValue={setSelectedCountries}
                                        allValues={allCountries}
                                        width="10em"
                                    />
                                    <DropdownSingleSelection
                                        labelText="Framework Access"
                                        isDisabled={false}
                                        selectedValue={frameworkAccess}
                                        setValue={setFrameworkAccess}
                                        allValues={disabledEnabledDropdownValues}
                                        width="10em"
                                    />
                                </TableFilters>
                                <SearchAndSave>
                                {exportSpinner ? (
                                        <CircularProgress />
                                    ) : (
                                        <ExportButton onClick={()=>exportCompanySummariesByFrameworkCSV(setExportSpinner,{frameworkId,countryIds:filters.countryIds,frameworkEnabled:filters.frameworkEnabled})}>
                                            <img src={iconDownload} alt="download" />
                                        </ExportButton>
                                    )}
                                    <SearchField
                                        placeholder="Search"
                                        callback={(value: string) =>
                                            clientSearchCallback(value)
                                        }
                                    />
                                </SearchAndSave>
                            </TableFiltersAndSearch>
                            {clientLoading?(
                                        <CircularProgress />
                                    ):
                            <CustomTable
                                header={clientsTableHeader}
                                tablePagination={clientTablePagination}
                                rows={clientTableRows}
                            />}
                        </>
                    )}
                    {currentTab === TabOptions.SETTINGS && (
                        <>
                            {
                                settingsLoading ?
                                <LoaderContainer>
                                    <CircularProgress />
                                </LoaderContainer> :
                                frameworkSettings.length > 0 ?
                                <SettingsWrapper>
                                    {frameworkSettings.map((fs) => {
                                        return <TogglesWrapper key={`${frameworkId}-${fs.name}-status-toggle`}>
                                            <StatusLabel>{fs.name}</StatusLabel>
                                            <StatusToggle
                                                labels={statusToggleLabels}
                                                state={fs.status}
                                                actionFunc={() => {
                                                    changeFrameworkSettingsStatus(fs.name, fs.status, fs.id);
                                                }}
                                                cellID={`${frameworkId}-${fs.name}-status-toggle`}
                                            />
                                        </TogglesWrapper>
                                    })}
                                </SettingsWrapper> :
                                <NotFoundTitle>
                                    No Settings Found
                                </NotFoundTitle>
                            }
                        </>
                    )}
                </div>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={messages.FRAMEWORK_SUCCESS_MESSAGE}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Change Framework Settings?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {messages.FRAMEWORK_SETTINGS_PROMPT_MESSAGE}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <ErrorModal
                openErrorModal={openErrorModal}
                message={frameworkError}
                setOpenErrorModal={setOpenErrorModal}
            />
        </>
    );
};

const DeleteButton = styled(RedButton)`
        cursor: pointer;
        border: 1.5px solid #ffffff;
        box-sizing: border-box;
        border-radius: 2px;
        color: #ffffff !important
        margin-left: 10px !important;
        width: 180px;
        font-size: 18px !important;

`;

const CustomTable = styled(NewTable)`
    && {
        && {
            &&.MuiTableContainer-root {
                max-height: 150px;
            }
        }
    }
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    height: 50%;
`;

const TitleAndButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2em;
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CountryText = styled('div')`
    font-style: normal;
    font-weight: bold;
    padding: 5px 0;
    h1 {
        margin: 0;
    }
`;

const BackLink = styled('div')`
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2em;
    text-decoration: underline;
    line-height: 1.5em;
    img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
`;

const LoaderContainer = styled('div')`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    align-content: center;
`;

const NotFoundTitle = styled('h1')`
    text-align: center;
    margin-top: 80px;
`;

const SettingsWrapper = styled('div')`
    margin: 30px 0px;
`;

const TogglesWrapper = styled('div')`
    display: flex;
    align-items: center;
    padding: 1em 8px;
`;

const StatusToggle = styled(Toggle)`
    margin-left: 0.75em;
`;

const StatusLabel = styled('div')`
    min-width: 8em;
    font-size: 1.2em;
`;

export default FrameworkDetails;
