import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { CircularProgress } from '@mui/material';

import NewTable from '../../components/Table/Table';

import { TableRow as TableRowInter } from '../../components/Table/types';
import { DropdownValueType } from '../../components/base/Dropdowns/types';
import {
    CampaignListPage,
    CampaignListModel,
    Filters,
    CampaignOrder,
    Framework
} from './campaignTypes';

import {
    SearchField,
    DropdownSingleSelection,
    ExportButton,
    SuccessModal
} from '../../components';

import {
    getHeaderData,
    setTableRows,
    getFilters,
    campaignStatus,
    exportCampaignCSV
} from './campaignHelpers';

import { ALL_CAMPAIGNS, DELETE_CAMPAIGN, LIST_COUNTRIES, LIST_FRAMEWORKS } from './campaignQueries';
import iconDownload from '../../assets/images/download-btn.svg';
import { dynamicSortWithForceTop } from '../../utils/helpers';
import { campaignDeleteFailed, campaignDeleteSuccessful } from '../../utils/constants';
import FailureModal from '../../components/base/FailureModal';
import { UserStore } from '../../context/user-store';

const ManageCampaigns: React.FC = () => {
    // dropdown values
    const { loading:loadingListCountries, error:errorListCountries, data:dataListCountries } = useQuery(LIST_COUNTRIES, {
        variables: {
            pagination: {
                order: {
                    field: 'countryCode',
                    isAsc: true
                },
            }
        }
    });
    const { loading:loadingFrameworks, error:errorFrameworks, data:dataFrameworks } = useQuery(LIST_FRAMEWORKS, {
        variables: {
            pagination: {
                order: {
                    field: 'name',
                    isAsc: true
                },
            }
        }
    });

    const [allFrameworks, setAllFrameworks] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: '',
        }
    ]);
    const [allCountries, setAllCountries] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: '',
            value: ''
        }
    ]);
    const perpage = 50;
    const [selectedFramework, setSelectedFramework] = useState<string>('All');
    const [selectedStatus, setStatus] = useState<string>('All');
    const [selectedCountry, setSelectedCountry] = useState<string>('All');
    const [page, setPage] = useState<number>(0);
    const [tableRow, setRow] = useState<TableRowInter[]>();
    const [filters, setFilters] = useState<Filters>({
        countryId: undefined,
        frameworkId: undefined,
        status: undefined
    });
    const [searchText, setSearchText] = useState<string>('');
    const [campaignsListPage, setCampaignsListPage] = useState<CampaignListPage>();
    const [order, setOrder] = useState<CampaignOrder | undefined>();
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)

    const {
        state: { user },
    } = useContext(UserStore);

    const [deleteCamp] = useMutation(DELETE_CAMPAIGN ,{
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
           getCampains();
           setOpenSuccessModal(true);
        },
        onError: (err) => {
            setOpenErrorModal(true);
            console.log(err);
        }
    });
    
    const [getCampains] = useLazyQuery<CampaignListModel>(ALL_CAMPAIGNS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCampaignsListPage(res.campaignSummaries);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const handleCampaignModalPopup =(data: any) =>{
        const clientIds = user.clients?.map((item => item.id));
        const clientLocal = localStorage.getItem('client');
        let clientUUID = null;
        if (clientLocal) {
            const clientParsed = JSON.parse(clientLocal);
            clientUUID = clientParsed.id;
        }       
        if(data.cellType === 'delete'){
            deleteCamp({
                variables:{
                    id: data.cellId
                },
                context: {
                    headers: {
                        "pearl-client-uuid": clientUUID ? clientUUID : clientIds && clientIds[0]
                    }
                    
                }
            })
        }
    }

    useEffect(() => {
        if (loadingListCountries || errorListCountries) {
            return;
        }

        const countriesResponse = [...allCountries,...dataListCountries.countrySummaries.items];
        countriesResponse.unshift({
            id: -1,
            isoCode: 'All'
        });
        const countriesWithValue:DropdownValueType[] = countriesResponse.map(item => {
            return{
                id:item.id,
                name:item.isoCode,
                value:item.isoCode
            }
        })
        setAllCountries(countriesWithValue);
    }, [dataListCountries, loadingListCountries, errorListCountries]);

    useEffect(() => {
        if (loadingFrameworks || errorFrameworks) {
            return;
        }

        const frameworksResponse = [...dataFrameworks.frameworkSummaries.items];

        frameworksResponse.unshift({
            id: -1,
            name: 'All'
        });
        const frameworksModified = frameworksResponse.map((framework:Framework) => {
            return {
                id: framework.frameworkId,
                name: framework.name
            }
        })
        setAllFrameworks(frameworksModified);
    }, [dataFrameworks, loadingFrameworks, errorFrameworks]);

    const createSortHandler = (property: string) => {
        if(order && order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if(filters.status !=='ALL'){
        getCampains({
            variables: {
                filters,
                search: searchText,
                pagination: {
                    order: !!order ? order : {field:'campaignName',isAsc:true},
                    skip: page * perpage,
                    take: perpage
                }
            }
        });}
        else{
            getCampains()
        }
    }, [page, filters, searchText, order]);

    useEffect(() => {
        if (!campaignsListPage) {
            return;
        }
        const rows = setTableRows(
            campaignsListPage
        );
        setRow(rows);
    }, [campaignsListPage]);

    useEffect(() => {
        getFilters(selectedCountry, selectedStatus, selectedFramework, allCountries, allFrameworks, setPage, setFilters );
    }, [selectedStatus, selectedFramework, selectedCountry]);

    const headerData = getHeaderData(createSortHandler);

    const campaignSearchCallback = (value: string) => {
        setSearchText(value);
        setPage(0);
    };

    const tablePagination = {
        rowsPerPage: perpage,
        currentPage: page,
        handleChangePage,
        totalCount: campaignsListPage && campaignsListPage.total
    };

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Campaigns</h1>
                </NameAndCTAs>
                <FiltersAddAndSave>
                    <FiltersAndAdd>
                        <DropdownSingleSelection
                            labelText="Country code"
                            isDisabled={false}
                            selectedValue={selectedCountry}
                            setValue={setSelectedCountry}
                            allValues={allCountries}
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText="Framework"
                            isDisabled={false}
                            selectedValue={selectedFramework}
                            setValue={setSelectedFramework}
                            allValues={allFrameworks}
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText="Status"
                            isDisabled={false}
                            selectedValue={selectedStatus}
                            setValue={setStatus}
                            allValues={campaignStatus.sort(dynamicSortWithForceTop('name', 'All'))}
                            width="10em"
                        />
                    </FiltersAndAdd>
                    <SearchAndSave>
                        {exportSpinner ? (
                            <CircularProgress />
                        ) : (
                            <ExportButton onClick={() => exportCampaignCSV(setExportSpinner, filters)}>
                                <img src={iconDownload} alt="download" />
                            </ExportButton>
                        )}
                        <SearchField placeholder="Search" callback={(value: string) => campaignSearchCallback(value)} />
                    </SearchAndSave>
                </FiltersAddAndSave>
                <TableOrSplash>
                    {loadingListCountries||loadingFrameworks ? (
                        <CircularProgress />
                    ) : (
                        <NewTable header={headerData} tablePagination={tablePagination} rows={tableRow} onAction={handleCampaignModalPopup} />
                    )}
                </TableOrSplash>
            </MainWrapper>
            
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={campaignDeleteSuccessful}
            />
            <FailureModal
                openState={openErrorModal}
                hideDuration={6000}
                setOpen={setOpenErrorModal}
                message={campaignDeleteFailed}
            />
        </>
    );
};

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;
const FiltersAddAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const FiltersAndAdd = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const TableOrSplash = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

export default ManageCampaigns;
