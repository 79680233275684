import React, { useState, useLayoutEffect, useRef, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as StarIcon } from '../../assets/images/icon-order.svg';
import arrowIcon from '../../assets/images/icon-indicator.svg';
import deleteIcon from '../../assets/images/delete-bin-grey.svg';

import {
    TableProps,
    Header,
    TableRow as TableRowInter,
    TableCell as TableCellInter
} from './types';
import Toggle from '../base/Toggle';
import NoDataScreen from '../Messages/NoDataScreen';
import * as messages from '../../utils/Constant/messages';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { UserStore } from '../../context/user-store';
import { RedButton } from '../base/Buttons';
import DialogBox from '../base/DialogBox/DialogBox';


const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

const StyledTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 700
    }
}));

const NewTable: React.FC<TableProps> = ({
    header,
    tablePagination,
    rows,
    onAction
}) => {

    const tableRef = useRef<HTMLDivElement>(null);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [deleteConfrimMessage, setConfrimMessage] = useState<string>('');
    const [deleteConfirmation, setConfirmation] = useState<boolean>(false);
    const [deleteCellDetails, setDeleteCellDetails] = useState<TableCellInter>();

    const [maxHeight, setMaxHeight] = useState<number>(0);

    const {
        state: { user },
    } = useContext(UserStore);

    useEffect(() => {
        if(deleteConfirmation){
            const deleteCellList: TableCellInter[] = [];
            rows?.forEach(row => {
                const deleteCells = row.tableCells.filter(cell => cell.cellType === 'delete');
                deleteCellList.push(...deleteCells);
            });
           
            const data = deleteCellList.filter(t => t.cellID == deleteCellDetails?.cellID 
                                                    && t.cellType === 'delete' && t.cellName === deleteCellDetails?.cellName)
            if(data.length === 1){
               onAction && onAction(data[0]);
            }
           setConfirmation(false); 
           setDeletePopup(false);
        }

    },[deleteConfirmation]);

    useLayoutEffect(() => {
        const tableEl = tableRef.current;
        if (tableEl && maxHeight === 0) {
            const tableMaxHeight = window.innerHeight - tableEl.offsetTop;
            if (tableMaxHeight < 600) {
                setMaxHeight(600)
            } else {
                setMaxHeight(tableMaxHeight)
            }
        }
    });

    return (
        <>
            {rows && rows.length === 0 ?
                <NoDataScreen title={messages.NODATASCREENTITLE} description={messages.NODATASCREENDESCRIPTION}/>
                :
                <TableWrapper
                    id="table"
                    ref={tableRef}
                    maxHeight={maxHeight + 'px'}>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={tablePagination.totalCount || 0}
                        rowsPerPage={tablePagination.rowsPerPage}
                        page={tablePagination.currentPage}
                        onPageChange={(event: any, pageNumber: any) => tablePagination.handleChangePage(event, pageNumber)}
                        style={{
                            alignSelf: 'flex-end',
                            overflow: 'visible'
                        }}
                        component="div"
                    />
                    <TableContainer component={Paper} style={{
                        boxShadow: 'inset 0px -1px 0px #E7E7ED',
                        border: '1px solid #E7E7ED'
                    }}>
                        <Table style={{ minWidth: 700 }} aria-label="customized table" >
                            <TableHead>
                                <TableRow>
                                    {header.map((headerRow: Header, index: number) => (
                                        <StyledTableCell key={index}>
                                            { headerRow.isHeaderSortable ?
                                                (
                                                    <TableSortLabel
                                                        active={true}
                                                        direction={headerRow.sortDirection}
                                                        onClick={() => headerRow.sortingFunc &&
                                                            headerRow.sortingFunc()
                                                        }
                                                        IconComponent={() => (
                                                            <SvgIcon component={StarIcon} style={{
                                                                marginLeft: '4px',
                                                                order: -1,
                                                                marginRight: '-10px',
                                                            }} viewBox="0 0 24 12" />
                                                        )}
                                                    >
                                                        {headerRow.headerName}
                                                    </TableSortLabel>
                                                )
                                                :
                                                (
                                                    <>
                                                        {headerRow.headerName}
                                                    </>
                                                )
                                            }
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows && rows.map((row: TableRowInter, index: number) => (
                                    <StyledTableRow key={index}>
                                        {row.tableCells.map((cell: TableCellInter, index: number) => {
                                            if (cell.cellType === 'boolean'
                                                && cell.toggleLabels !== undefined
                                                && cell.currentState !== undefined
                                                && cell.actionFunc !== undefined
                                            ) {

                                                return (
                                                    <StyledTableCell key={index}>
                                                        <div style={{ visibility: cell.hideCell ? 'hidden' : 'visible'}}>
                                                            <Toggle
                                                                labels={cell.toggleLabels}
                                                                state={cell.currentState}
                                                                actionFunc={cell.actionFunc}
                                                                cellID={cell.cellID}
                                                            />
                                                        </div>
                                                    </StyledTableCell>
                                                )
                                            } else if (cell.cellType === 'action') {
                                                return (
                                                    <StyledTableCell
                                                        key={index}
                                                        onClick={(e: any) =>
                                                            cell.actionFunc && cell.actionFunc(e)
                                                        }
                                                        style={{ cursor: 'pointer' }}

                                                    >
                                                        <div style={{ visibility: cell.hideCell ? 'hidden' : 'visible'}}>
                                                            <img src={arrowIcon} alt="icon to view country" />
                                                        </div>
                                                    </StyledTableCell>
                                                )
                                            } else if (cell.cellType === 'delete') {
                                                return (
                                                    <StyledTableCell
                                                        key={index}
                                                        onClick={(e: any) =>{
                                                            if(user.role === 'ADMIN'){
                                                                setDeletePopup(true);
                                                                setConfrimMessage(`${cell.cellName}`)
                                                                setDeleteCellDetails(cell);
                                                            }
                                                        }
                                                        }
                                                        style={{ cursor: 'pointer' }}

                                                    >
                                                        <div style={{ visibility: cell.hideCell ? 'hidden' : 'visible'}} >
                                                            <img src={deleteIcon} alt="delete" />
                                                        </div>
                                                    </StyledTableCell>
                                                )
                                            }else if (cell.cellType === 'string') {
                                                return (
                                                    <StyledTableCell
                                                        key={index}
                                                        style={{ paddingLeft: cell.isCellSortable ? '33px'  : ''}}>
                                                        <div style={{ visibility: cell.hideCell ? 'hidden' : 'visible'}}>
                                                            {cell.cellName}
                                                        </div>
                                                    </StyledTableCell>
                                                )
                                            }
                                        })}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>
            }
            { deletePopup &&
                <DialogBox 
                content={
                    <div>
                      <p><strong>Warning:</strong> Deleting campaign "{deleteConfrimMessage}" will permanently remove it.</p>
                      <p>Are you sure you want to continue?</p>
                    </div>
                  }
                open={deletePopup} 
                buttonCancelText={'Cancel'}
                handleClose={()=> {
                    setConfirmation(false);
                    setDeletePopup(false);
                }} 
                handleSubmit={()=> {
                    setConfirmation(true);
                    setDeletePopup(false);
                }}          
                />
            }       
        </>
    );
};

const TableWrapper = styled('div')<{ maxHeight?: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: ${props => props.maxHeight || '65vh'};
    margin-bottom: 10px;
`;

export default NewTable;
